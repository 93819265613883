import {
  Component,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import {
  IVendorLocation,
  IVendorOnboardingResult,
  IVendorOnboardingSave
} from 'src/app/store/vendor/vendor.model';
import { LoggerDatadogService, UserRole } from 'sustainment-component';
import {
  ICapabilitytProcess,
  ICapability,
  SupplierListModel
} from 'sustainment-models';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as ManufacturingHelper from 'sustainment-component';
import { OnboardingAPI } from 'src/app/api/onboarding.api';
import { Title } from '@angular/platform-browser';
import { regex } from 'src/app/regex/regex';
import { UserAccountQuery } from 'src/app/store/userAccount/user-account.query';
import { StateQuery } from 'src/app/store/state/state.query';

export enum RegistrationSteps {
  CompanyDetails,
  CompanySuggestions,
  CompanyTypeRegistration,
  UserProblems,
  WelcomeScreen
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  @Input() public vendorInfo: Partial<IVendorLocation>;
  @Input() public processes: ICapabilitytProcess[];
  @Input() public industries: ICapability[];
  @Input() public autoCompleteOnboarding = false;
  public userRoles = input.required<UserRole[]>();

  @Output() public logout = new EventEmitter<void>();
  @Output() public patchVendor = new EventEmitter<{
    vendor: IVendorOnboardingResult;
    roles: { id: number; text: string }[];
    problems: {
      id: number;
      text: string;
    }[];
  }>();
  @Output() public vendorUpdated = new EventEmitter<IVendorOnboardingSave>();
  @Output() public requestedToJoin = new EventEmitter<SupplierListModel>();

  public vendorSelected: SupplierListModel | null;
  public form = new FormGroup({
    name: new FormControl('', [Validators.maxLength(75), Validators.required]),
    address: new FormControl('', [
      Validators.maxLength(200),
      Validators.required
    ]),
    address2: new FormControl('', [Validators.maxLength(200)]),
    city: new FormControl('', [Validators.maxLength(200), Validators.required]),
    state: new FormControl(''),
    zip: new FormControl(''),
    county: new FormControl(''),
    country: new FormControl(''),
    latitude: new FormControl<number | null>(null),
    longitude: new FormControl<number | null>(null),
    website: new FormControl('', [
      Validators.pattern(regex.websiteUrlValidatorPattern)
    ])
  });
  public disableContinue = true;
  public loadCompaniesSuggestion = false;

  public suggestedVendors: SupplierListModel[] = [];

  public steps = RegistrationSteps;
  public currentStep = this.steps.CompanyDetails;
  public isInternationalCompany: boolean;
  public internationalAcknowledgmentAccepted: boolean;
  public selectedProblems: ManufacturingHelper.UserProblem[] = [];

  public roles: { id: number; text: string }[] = [];
  public problems: { id: number; text: string }[];

  public constructor(
    public loggerDatadogService: LoggerDatadogService,
    private _stateQuery: StateQuery,
    private onboardingApi: OnboardingAPI,
    private titleService: Title,
    private _userAccountQuery: UserAccountQuery
  ) {
    this.titleService.setTitle('Set up your account - Sustainment Portal');
  }

  public ngOnInit(): void {
    this.setForm();

    if (this.autoCompleteOnboarding) {
      this.currentStep = this.steps.CompanyTypeRegistration;
    }
  }

  public setForm(): void {
    const vendorAddress = this.vendorInfo?.address;
    const state = this._stateQuery.getByStateId(
      Number(vendorAddress?.stateId)
    )?.abbreviation;

    this.form.patchValue({
      name: this.vendorInfo?.name || null,
      website: this.vendorInfo?.website || null,
      address: vendorAddress?.address || '',
      address2: vendorAddress?.address2 || '',
      city: vendorAddress?.city || '',
      state: state === 'OU' || !state ? '' : state,
      zip: vendorAddress?.postalCode || '',
      county: vendorAddress?.county || '',
      country: vendorAddress?.country || 'US'
    });
  }

  public emitVendorStateUpdate(): void {
    this.vendorUpdated.emit({
      name: this.form.value.name || '',
      website: this.form.value.website || '',
      processes: [],
      industries: [],
      address: {
        address: this.form.value.address || '',
        address2: this.form.value.address2 || '',
        city: this.form.value.city || '',
        stateId:
          this._stateQuery.getByNameOrAbbreviation(this.form.value.state || '')
            ?.stateId || 143,
        postalCode: this.form.value.zip || '',
        county: this.form.value.county || '',
        country: this.form.value.country || '',
        unit: ''
      }
    });
  }

  public onClickBack(): void {
    if (this.currentStep === this.steps.UserProblems) {
      this.disableContinue = true;
    }
    if (this.currentStep !== this.steps.CompanyTypeRegistration) {
      this.currentStep -= 1;
    } else {
      this.currentStep =
        this.suggestedVendors.length > 0
          ? this.steps.CompanySuggestions
          : this.steps.CompanyDetails;
    }

    this.emitVendorStateUpdate();
  }

  public onContinueClick(): void {
    this.disableContinue = true;
    switch (this.currentStep) {
      case this.steps.CompanyDetails: {
        this.emitVendorStateUpdate();

        const country = this.form.controls.country.value || '';

        this.loadCompaniesSuggestion = true;
        this.vendorInfo.name = this.form.value.name!;

        this.onboardingApi
          .getOpensearchResults({
            companyName: this.form.value.name || '',
            state: country == 'US' ? this.form.controls.state.value || '' : '',
            website: this.getWebsiteDomain(this.form.value.website || ''),
            country: country == 'US' ? '' : country
          })
          .pipe(map((x) => x))
          .subscribe((results) => {
            if (results) {
              results.forEach((vendor) => {
                if (vendor && vendor && vendor.processes)
                  vendor.processes =
                    ManufacturingHelper.mapVendorProfileProcessSubprocess(
                      vendor,
                      this.processes
                    );
              });

              this.suggestedVendors = results;
              this.currentStep =
                this.suggestedVendors.length > 0
                  ? this.steps.CompanySuggestions
                  : this.steps.CompanyTypeRegistration;
              this.logVendorStep();

              if (this.suggestedVendors.length === 0) {
                this.onNoneClicked();
              }
              this.loadCompaniesSuggestion = false;
            }
          });
        break;
      }
      case this.steps.CompanySuggestions:
        if (this.vendorSelected?.isRegistered) {
          this.requestedToJoin.emit(this.vendorSelected);
        } else {
          this.currentStep = this.steps.CompanyTypeRegistration;
        }
        break;

      case this.steps.CompanyTypeRegistration:
        this.selectedProblems = this.userRoles()
          .filter((role) =>
            this.roles.map((e) => e.id).includes(role.userRoleId)
          )
          .reduce((acc: ManufacturingHelper.UserProblem[], role) => {
            role.problems.forEach((problem) => {
              if (!acc.some((p) => p.userProblemId === problem.userProblemId)) {
                acc.push(problem);
              }
            });
            return acc;
          }, [])
          .sort((a, b) => a.userProblemId - b.userProblemId);
        if (this.selectedProblems.length)
          this.currentStep = this.steps.UserProblems;
        else this.saveVendor();
        break;
      case this.steps.UserProblems:
        this.saveVendor();
        break;
      default:
        break;
    }
  }

  private getWebsiteDomain(website: string): string {
    if (!website) return '';

    website = website.replace(/\s+/g, '');

    if (!website.startsWith('http://') && !website.startsWith('https://')) {
      website = 'http://' + website;
    }

    const parsedUrl = new URL(website);
    return parsedUrl.host;
  }

  public enableContinue(event: SupplierListModel | boolean): void {
    this.disableContinue = !event;

    if (
      this.isInternationalCompany &&
      !this.internationalAcknowledgmentAccepted
    )
      this.disableContinue = true;

    if (typeof event !== 'boolean' && event?.sustainmentId) {
      this.vendorSelected = event;
      this.disableContinue = this.vendorSelected.isSelected ? false : true;
    }
  }

  public onNoneClicked(): void {
    this.vendorSelected = null;
    this.currentStep = this.steps.CompanyTypeRegistration;
  }

  public saveVendor(): void {
    this.disableContinue = true;

    this.emitVendorStateUpdate();

    const vendor: IVendorOnboardingResult = {
      name: this.vendorSelected?.name || this.form.value.name || '',
      primaryAddress: {
        address:
          this.vendorSelected?.address?.addressLine ||
          this.form.value.address ||
          '',
        address2:
          this.vendorSelected?.address.addressLine2 ||
          this.form.value.address2 ||
          '',
        postalCode: this.form.value.zip || '',
        state: this.form.value.state || '',
        latitude:
          this.vendorSelected?.address?.latitude ||
          this.form.value.latitude ||
          null,
        longitude:
          this.vendorSelected?.address?.longitude ||
          this.form.value.longitude ||
          null,
        city: this.vendorSelected?.address?.city || this.form.value.city || '',
        county:
          this.vendorSelected?.address?.county || this.form.value.county || '',
        country: this.form.controls.country.value || ''
      },
      logo: this.vendorSelected?.logo || null,
      website: this.vendorSelected?.website || this.form.value.website || '',
      processes: [],
      industries: [],
      sustainmentId:
        this.vendorSelected?.sustainmentId ||
        this._userAccountQuery.getValue().sustainmentId,
      isBuyer: this.userRoles().some(
        (role) =>
          role.buyerRole &&
          this.roles.map((e) => e.id).includes(role.userRoleId)
      ),
      isSupplier: this.userRoles().some(
        (role) =>
          role.supplierRole &&
          this.roles.map((e) => e.id).includes(role.userRoleId)
      ),
      isOther: this.userRoles().some(
        (role) =>
          role.otherRole &&
          this.roles.map((e) => e.id).includes(role.userRoleId)
      )
    };

    this.patchVendor.emit({
      vendor,
      roles: this.roles,
      problems: this.problems
    });
  }

  public internationalCompanyChange(event: boolean): void {
    this.isInternationalCompany = event;
  }

  public selectedRoles(roles: { id: number; text: string }[]): void {
    this.roles = roles;
  }

  public selectProblems(problems: { id: number; text: string }[]): void {
    this.problems = problems;
  }

  public acknowledgeInternationalAccepted(event: boolean): void {
    this.internationalAcknowledgmentAccepted = event;

    if (this.form.valid) {
      this.disableContinue = !event;
    }
  }

  private logVendorStep(): void {
    this.loggerDatadogService.info(
      `Onboarding step ${this.steps[this.currentStep]}`,
      { data: this.vendorInfo, action: 'onboarding' }
    );
  }
}
