<div
  class="company-detail"
  [formGroup]="form"
>
  <div class="row">
    <div class="col-12">
      <span class="registration-title">Company Details</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="registration-sub-title">
        Enter your company details to create a profile.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label class="registration-label">Country *</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="p-field">
        <p-dropdown
          [options]="countries"
          optionLabel="name"
          optionValue="code"
          [style]="{ width: '100%' }"
          formControlName="country"
        />
      </div>
    </div>
  </div>
  @if (isInternationalCompany()) {
    <div class="row row-international-company">
      <div class="international-manufacturer">
        <p-checkbox
          id="internationalManufacturer"
          name="internationalManufacturer"
          [binary]="true"
          (onChange)="acknowledgmentChange()"
        >
        </p-checkbox>

        <div class="label-international">
          <label for="internationalManufacturer"
            >I’m a manufacturer who doesn’t operate in the United States. I
            understand the
            <a
              href="https://www.sustainment.com/community-guidelines"
              target="_blank"
              ><b><u>restrictions</u></b></a
            >
            that apply to my account.
          </label>
        </div>
      </div>
    </div>
  }
  <div class="row">
    <div class="col-12">
      <label class="registration-label">Company Name *</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="p-field">
        <input
          id="name"
          type="text"
          pInputText
          placeholder="Enter company name"
          formControlName="name"
          maxlength="75"
          asciiOnly
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label class="registration-label">Street Address *</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="p-field">
        <s-location
          [searchResults]="(searchResults$ | async) || []"
          (searchLocation)="searchLocation($event)"
          placeholder="Enter your primary facility address"
          [isRequired]="true"
          [showRequiredMessage]="false"
          [location]="form.value.address"
          (locationChange)="onLocationChanged($event)"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label class="registration-label">Apt, ste, bldg.</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="p-field">
        <input
          type="text"
          pInputText
          placeholder="Apt, ste, bldg."
          maxlength="200"
          formControlName="address2"
        />
      </div>
    </div>
  </div>
  <div [style]="{ display: 'flex', width: '100%' }">
    <div
      class="p-field"
      [style]="{ width: '100%' }"
    >
      <label class="registration-label">City *</label>
      <input
        id="website"
        type="text"
        pInputText
        placeholder="Enter city"
        formControlName="city"
        maxlength="75"
      />
    </div>
    <div
      class="p-field"
      [style]="{ width: '100%' }"
    >
      <div
        [style]="{ display: 'flex', flexDirection: 'column', width: '100%' }"
      >
        <label class="registration-label">State</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="stateAbbreviations()"
          formControlName="state"
        />
      </div>
    </div>
    <div
      class="p-field"
      [style]="{ width: '100%' }"
    >
      <label class="registration-label">Zip code</label>
      <input
        id="website"
        type="text"
        pInputText
        placeholder="Enter zip code"
        formControlName="zip"
        maxlength="75"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label class="registration-label">Website</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="p-field">
        <input
          id="website"
          type="text"
          pInputText
          placeholder="Enter the company website"
          formControlName="website"
          maxlength="75"
        />
      </div>
    </div>
  </div>
  <div class="row registration-comments">
    <div class="col-12">
      <span>* indicates required field</span>
    </div>
  </div>
</div>
